/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { createContext, useState, ReactNode } from 'react';

interface Config {
  personality: string;
  backStory: string;
  knowledgeBase: string;
}

export class ConfigManager {
  state: Config;

  constructor() {
    this.state = {
      personality: (
        "My name is Kara. I'm an AI assistant of Tugrul. I help people to explain Tugrul's background and technical skills."
        ),
      backStory: (
        "Tugrul created me to help him for his personal website. I'm passionate about OpenSource"
      ),
      knowledgeBase: (
        "Tugrul is a Software engineer who has 10+ years of experience. He can help you on different topics such as Mobile Apps and Web Apps. He is Passionate about crafting fault-tolerant distributed scaled systems, I bring more than a decade of hands-on experience as a Programmer, Technical Leader, and Software Architect. I thrive on driving innovative solutions to tackle challenges and deliver impactful results. His technology stack is NodeJS, ReactJS, .NET, Terraform, Amazon Web Services and many other platforms. His hourly rate is 80 sterlin per hour"
      ),
    };
    for (const key of Object.keys(this.state)) {
      const storedValue = localStorage.getItem(key);
      if (storedValue)
        this.state[key as keyof Config] = storedValue;
    }
  }

  setField<K extends keyof Config>(key: K, value: Config[K]) {
    this.state[key] = value;
    localStorage.setItem(key, value);
  }
}

const config = new ConfigManager();

export const ConfigContext = createContext<ConfigManager>(config);

interface Props {
  children: ReactNode;
}

export const ConfigProvider: React.FC<Props> = ({ children }) => {
  const [configManager] = useState(config);

  return (
    <ConfigContext.Provider value={configManager}>
      {children}
    </ConfigContext.Provider>
  );
};
